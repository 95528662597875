<template>
  <div>
    <b-row v-if="tariffPerformerPrices.length > 0">
      <b-col>
        <b-card class="my-card m-0">
          <b-table
            striped
            small
            id="myTabless"
            bordered
            selectable
            selected-variant="primary"
            select-mode="single"
            responsive="sm"
            v-hotkey="{
              insert: getCreat,
              enter: editForm,
              delete: deleteTariffPrice,
            }"
            :items="tariffPerformerPrices"
            :fields="fields"
            head-row-variant="secondary"
            @row-contextmenu="handleContextMenuEvent"
            @row-clicked="clicked"
            @row-dblclicked="editForm"
          >
            <template #cell(by_prices)="data">
              <span v-for="(prices, i) in data.item.by_price" :key="i">
                {{ prices.percent }}={{ prices.price }}%
              </span>
            </template>
            <template #cell(by_distances)="data">
              <span v-for="(distance, i) in data.item.by_distance" :key="i">
                {{ distance.percent }}={{ distance.distance }}%
              </span>
            </template>
            <template #cell(by_times)="data">
              <span v-for="(time, i) in data.item.by_time" :key="i">
                {{ time.percent }}={{ time.time }}%
              </span>
            </template>
          </b-table>
          <vue-context ref="menu">
            <li>
              <b-link
                class="d-flex align-items-center"
                @click.prevent="getCreat"
              >
                <feather-icon icon="PlusIcon" />
                <span class="ml-75">Добавить</span>
                <span style="margin-left: 45px">Ins</span>
              </b-link>

              <b-link
                class="d-flex align-items-center"
                @click.prevent="editForm"
              >
                <feather-icon icon="Edit3Icon" />
                <span class="ml-75">Изменить</span>
                <span style="margin-left: 30px">Enter</span>
              </b-link>
              <b-link
                class="d-flex align-items-center"
                @click.prevent="deleteTariffPrice"
              >
                <feather-icon icon="EditIcon" />
                <span class="ml-75">Удалить</span>
              </b-link>
              <hr />
              <b-link class="d-flex align-items-center" @click="autoUpdate">
                <img :src="iconChect" />
                <span class="ml-75">Автообновление</span>
              </b-link>
            </li>
          </vue-context>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import VueContext from "vue-context";
import { resizeable } from "@/utils/TableFunctions/resizeable.js";
import { cellSelect } from "@/utils/TableFunctions/cellSelect";

export default {
  components: {
    VueContext,
  },
  props: ["tariffPerformerPrices"],
  data() {
    return {
      fields: [
        { key: "id", label: "ID", sortable: true, thStyle: { width: "10px" } },
        {
          key: "base_tariff_performer_settings_id",
          label: "ID настройки",
          sortable: true,
          thStyle: { width: "10px" },
        },
        {
          key: "performer_request_type_name",
          label: "Тип запроса водителя",
          sortable: true,
          thStyle: { width: "50px" },
        },
        {
          key: "by_prices",
          label: "По цене",
          sortable: true,
          thStyle: { width: "50px" },
        },
        {
          key: "by_distances",
          label: "Порасстоянию",
          sortable: true,
          thStyle: { width: "50px" },
        },
        {
          key: "by_times",
          label: "По время",
          sortable: true,
          thStyle: { width: "50px" },
        },
      ],
      id: null,
      is_active: false,
      iconChect: require("@/assets/images/icons/square.svg"),
      iconsSquare: require("@/assets/images/icons/square.svg"),
      iconsCheck: require("@/assets/images/icons/checkSquareIcon.svg"),
    };
  },
  mounted() {
    resizeable();
  },
  methods: {
    handleContextMenuEvent(item, index, event) {
      event.preventDefault();
      this.$refs.menu.open(event);
      this.id = item.id;
    },
    getPrice() {
      this.$emit("getPrice");
    },
    autoUpdate() {
      this.iconChect = this.is_active ? this.iconsSquare : this.iconsCheck;
      this.is_active = !this.is_active;
      this.$emit("timerAfto", this.is_active);
    },
    getCreat() {
      this.$bvModal.show(this.$route.name + "CreateModal");
    },
    editForm() {
      let id = this.id;
      this.$emit("editForm", id);
    },
    deleteTariffPrice() {
      let id = this.id;
      this.$emit("deleteTariffPrice", id);
    },
    clicked(item, index, event) {
      this.id = item.id;
      this.$store.state.filterPlus.tableIndex = index;

      const clickedElement = event.target;
      const clickedCell = clickedElement.closest("td");

      if (!clickedCell) {
        return;
      }

      const columnIndex = Array.from(clickedCell.parentNode.children).indexOf(
        clickedCell
      );

      if (columnIndex < 0 || columnIndex >= this.fields.length) {
        return;
      }

      const clickedField = this.fields[columnIndex];
      this.$store.state.filterPlus.keyFilter = clickedField.key;
      this.$store.state.filterPlus.filterPlus = event.target.innerHTML.trim();
      cellSelect();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/table.scss";
.my-card .card-body {
  padding: 0px;
}
.my-card {
  overflow: auto;
  height: calc(100vh - 92px);
}
@media (max-height: 700px) {
  .my-card {
    overflow: auto;
    height: 530px;
  }
}
</style>
